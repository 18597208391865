<template>
  <transition name="fade">
    <div v-show="this.show" class="loadingIndex">
      <div class="Index-load-div">
        <div class="Index-companyName-div">{{this.case}}</div>
        <div class="Index-projectName-div">欢迎使用</div>
        <img src="@/assets/big_logo.png" class="img-logo">
      </div>
    </div>
  </transition>
</template>

<script>
import {getUserInfoByWechatCode} from "@/api/routeApi";

export default {
  name: "Auth",
  data() {
    return {
      show: false,
      fromPath: "",
      case: "",
    }
  },
  created() {
    this.getUserInfoByCode()
    let that = this
    setTimeout(function () {
      that.show = true
    }, 100)
  },
  methods: {
    getUserInfoByCode() {
      console.log(this.$route.query.code)
      getUserInfoByWechatCode(this.$route.query.code).then(res => {
        let data = res.data
        console.log(data)
        this.$store.commit('COMMIT_USERID', data.data.userId)
        this.$store.commit('COMMIT_NAME', data.data.name)
        //TODO 记得改
        this.$store.commit('COMMIT_ROLES', ['18061', '21014', '13006', '18215', '19150', '17223', '4002', '19181', '18280', '17039', '12005', '16034', '17032', '12007', '16166', '16055', '12064', '13117', '16086', '14018', '19060', '18005', '13072', '4008', '12066', '5001', '17128', '21115', '22035'])
        this.show = false

        let that = this

        if (that.$route.query.state) {
          if (that.$route.query.state === '0') {
            this.case = "CRM"
            that.$router.replace({path: '/index'})
          } else {
            const state = that.$route.query.state
            var p = state.split("-")
            var pate = p[0]
            var id = p[1]
            that.$router.replace({path: pate + '/' + id})
          }

        } else {
          this.case = "CRM"
          that.$router.replace({path: '/index'})
        }

      }).catch(error => {
        const code = error.message.split(':')[0]
        const msg = error.message.split(':')[1]
        if (code === '501') {
          this.$router.replace({path: '/noAuthority', query: {message: msg}})
        } else {
          this.$router.replace({path: '/error', query: {message: "系统错误"}})
        }
      })
    }
  }
}
</script>

<style scoped>
.img-logo {
  width: 70%;
  position: fixed;
  left: 0px;
  bottom: 20px;
  right: 0px;
  margin: 0 auto;
}

.loadingIndex {
  color: #4F77AA;
  position: fixed;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 999999;
  background-color: #fff;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.Index-load-div {
  top: 20%;
  position: relative;
}

.Index-companyName-div {
  font-size: 50px;
  line-height: 1;
  text-align: center;
  color: #4F77AA;
  font-weight: 700;
}

.Index-projectName-div {
  font-size: 14px;
  line-height: 1;
  text-align: center;
  color: #999999;
  font-weight: 400;
  margin-top: 80px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

</style>
